import React from 'react'
import { VolumeUpOutlined } from '@mui/icons-material'
import { SpeakerButtonBaseStyles } from './SpeakerButton.styles'
import { ButtonPropTypes } from '..'

export const SpeakerButtonComponent: React.FC<ButtonPropTypes> = ({
  ...props
}) => {
  return (
    <SpeakerButtonBaseStyles {...props}>
      <div>
        <VolumeUpOutlined />
      </div>
    </SpeakerButtonBaseStyles>
  )
}
