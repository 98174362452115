import React from 'react'
import { ButtonComponent } from 'components'
import { useTranslation } from 'react-i18next'
import { ActionGroupPropTypes } from './ActionGroup.types'

import { RowStyles } from './ActionGroup.styles'

export const ActionGroupComponent: React.FC<ActionGroupPropTypes> = ({
  footer,
  onConfirm,
  onClose,
  variant
}) => {
  const { t } = useTranslation()
  const getButtonColor = () => {
    if (variant === 'error') return 'error'

    if (onConfirm) return 'primary'

    return 'secondary'
  }
  return (
    <RowStyles>
      {footer.buttons ? (
        footer.buttons.map((props, index) => (
          <ButtonComponent
            color={getButtonColor()}
            key={`button-${index.toString()}`}
            {...props}
          />
        ))
      ) : (
        <>
          <ButtonComponent
            variant={onConfirm ? 'outlined' : 'contained'}
            color={getButtonColor()}
            mode={!onConfirm ? undefined : 'fallbackBtn'}
            value={
              onConfirm
                ? t('general.actions.cancel')
                : t('general.actions.confirm')
            }
            onClick={onClose}
          />
          {onConfirm && (
            <ButtonComponent
              variant='contained'
              color='secondary'
              value={t('general.actions.confirm')}
              onClick={onConfirm}
            />
          )}
        </>
      )}
    </RowStyles>
  )
}
