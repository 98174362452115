export default {
  username: 'Username',
  imageAlt: {},
  status: {
    error: {
      somethingWentWrong: 'Something went wrong',
      sessionError: 'Unable to verify the session, please try again later...'
    }
  },
  actions: {
    back: 'Back',
    cancel: 'Cancel',
    moveOn: 'Move on',
    edit: 'Edit',
    delete: 'Delete',
    remove: 'Remove',
    next: 'Next',
    save: 'Save {{ addOn }}',
    update: 'Update {{ addOn }}',
    select: 'Select',
    confirm: 'Confirm',
    revoke: 'Revoke',
    close: 'Close'
  },
  or: 'or'
}
