import { api } from 'services/api/api'
import { AuthValidateUsernameResponseTypes } from './ValidateUsername.types'

const Get = async (username: string) => {
  return api
    .get<AuthValidateUsernameResponseTypes>(
      `/fusionauth/validateUsername/${username}`
    )
    .then((data) => data.data)
    .catch((error) => error.response.data)
}

export default {
  get: Get
}
