import React from 'react'

import {
  LanguageSelectorMenuListStyles,
  LanguageSelectorMenuStyles
} from './LanguageSelectorMenu.styles'
import { LanguageSelectorMenuPropTypes } from './LanguageSelectorMenu.types'

export const LanguageSelectorMenuComponent: React.FC<
  LanguageSelectorMenuPropTypes
> = ({ children, onClose, ...rest }) => {
  return (
    <LanguageSelectorMenuStyles
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      onClose={onClose}
      {...rest}
    >
      <LanguageSelectorMenuListStyles
        onClick={() => {
          if (onClose) onClose({}, 'backdropClick')
        }}
      >
        {children}
      </LanguageSelectorMenuListStyles>
    </LanguageSelectorMenuStyles>
  )
}

export * from './LanguageSelectorMenu.types'
