import { teacherApi } from 'services/api/api'

const getUserInfo = async (userToken: string) => {
  const response = await teacherApi.get(`/students/user/info`, {
    headers: {
      Authorization: `Bearer ${userToken}`
    }
  })

  return response.data
}

export default {
  get: getUserInfo
}
