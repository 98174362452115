/* eslint-disable no-console */
import { AxiosResponse } from 'axios'
import process from 'process'

export const errorInterceptor = (response: AxiosResponse) => {
  if (response.status !== 200) {
    if (process.env.NODE_ENV === 'development')
      console.error(
        `Looks like there was a problem. Status Code: ${response.data.status}`
      )
    return Promise.reject(response)
  }
  return Promise.resolve(response)
}
