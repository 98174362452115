import styled from 'styled-components'
import { from } from 'styles'

export const ViewHeaderStyles = styled.div`
  display: none;

  ${from.md} {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }
`
