import { Box } from '@mui/material'
import { ViewLayoutStyles } from 'layout'
import styled from 'styled-components'

export const LogoHolderStyles = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2.5rem;

  img {
    object-fit: contain;
    width: 100%;
    height: auto;
    max-width: 25rem;
  }
`

export const HomeViewStyles = styled(ViewLayoutStyles)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
`

export const HomeViewAdditionalContentStyles = styled(Box)`
  padding: 2.4rem 5.2rem;

  a {
    text-decoration-color: inherit;
    color: inherit;
  }
`
