import { Typography } from '@mui/material'
import React, { useState } from 'react'
import { SpeakerButtonComponent } from 'components'
import { HeaderTitlePropTypes } from './HeaderTitle.types'
import { HeaderTitleWrapperStyles } from './HeaderTitle.styles'

export const HeaderTitleComponent: React.FC<HeaderTitlePropTypes> = ({
  title,
  audio,
  fontSize = 'default',
  ...rest
}) => {
  const [player, setPlayer] = useState<HTMLAudioElement | null>(null)

  return (
    <HeaderTitleWrapperStyles fontSize={fontSize} {...rest}>
      <Typography>{title}</Typography>
      <SpeakerButtonComponent
        onClick={() => {
          if (player) {
            setPlayer(null)
            player.pause()
          } else {
            const playerAudio = new Audio(audio)
            setPlayer(playerAudio)
            playerAudio.play()
          }
        }}
        variant='contained'
        color='secondary'
      />
    </HeaderTitleWrapperStyles>
  )
}
