import { Button } from '@mui/material'
import styled, { css } from 'styled-components'
import { Colors, FontSizes, FontWeights } from 'styles'
import { ButtonPropTypes } from './Button.types'

export const ButtonStyles = styled(Button)<ButtonPropTypes>`
  &.MuiButton-root {
    margin-top: none;
    box-shadow: none;
    text-transform: none;
    padding: 1rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    position: relative;
    font-weight: ${FontWeights.bold};

    opacity: 1;

    transition: all 0.3s ease-in-out;

    &:hover {
      box-shadow: none;
    }

    ${({ disabled }) =>
      disabled &&
      css`
        background-color: ${Colors.darkGrey};
        color: ${Colors.darkerGrey};
      `}

    ${({ isHidden }) =>
      isHidden &&
      `
      visibility: collapse;
      opacity: 0;
      margin-left: -12rem;
    `}

    ${({ smaller }) =>
      smaller &&
      `
      padding: 0;  
      width: 7rem;
    `}

    ${({ className }) =>
      className === 'btn-add' &&
      `
      font-size: ${FontSizes.normal};
      color: ${Colors.info};
      padding: 0;
      :hover {
        background-color: inherit;
      }
      svg {
        color: ${Colors.dark};
        font-size: ${FontSizes.h6};
        margin-bottom: 0.2rem;
      }
    `}

    ${({ fontSize = 'normal', size }) =>
      `font-size: ${size === 'small' ? FontSizes.small : FontSizes[fontSize]};`}

    ${({ color }) =>
      ['secondary', 'info'].some((value) => color?.includes(value)) &&
      `color: ${Colors.white};`}

    ${({ color }) =>
      color === 'info' &&
      `
      &:hover{
        color: ${Colors.info};
        background-color: ${Colors.infoLight};
      }

      &:active {
        color: ${Colors.white};
        background-color: ${Colors.infoDark};
      }
    `}


    ${({ color, variant }) =>
      (color === 'secondary' || color === 'primary') &&
      variant === 'outlined' &&
      `color: ${Colors[color]};
        &:hover {
          color: ${Colors.white};
          background-color: ${Colors.secondaryLight};
        }

        &:active {
          color: ${Colors.white};
          background-color: ${Colors.secondaryDark};
        }
      `}
      
    
    // SecondaryAlt
    ${({ mode, variant }) =>
      mode === 'secondaryAlt' &&
      `
        color: ${variant === 'outlined' ? Colors.light : Colors.white};
        background-color: ${
          variant === 'outlined' ? Colors.white : Colors.light
        };

        &:hover {
          color: ${variant === 'outlined' ? Colors.white : Colors.dark};
          background-color: ${
            variant === 'outlined' ? Colors.light : Colors.blue
          };
        }

        &:active {
          color: ${Colors.dark};
          background-color: ${
            variant === 'outlined' ? Colors.blue : Colors.lightBlue
          };
        }
        ${variant === 'outlined' && `border-color: ${Colors.light};`}
    `}

    // Fallback Btn
    ${({ mode }) =>
      mode === 'fallbackBtn' &&
      `
        border: 0.1rem solid ${Colors.lightBlue};
        color: ${Colors.dark};
        &:hover{
          border-color: transparent;
          background-color: ${Colors.lightBlue};
        }

        &:active{
          border-color: transparent;
          background-color: ${Colors.blue};
        }
    
    `}

    // Select Btn
    ${({ mode, selected }) =>
      mode === 'selectBtn' &&
      `
      padding-left: 1rem;
      padding-right: 1rem;
      color:${selected ? Colors.success : Colors.dark};
      border: 0.1rem solid ${selected ? Colors.success : Colors.blue};
       &:hover{
        background-color: ${Colors.white};
       } 

       svg{
         font-size: 1.8rem;
         fill: ${selected ? Colors.success : Colors.blue}
       }
    `}

    // Feide Btn
    ${({ mode }) =>
      mode === 'feideBtn' &&
      `
       color: ${Colors.white};
       background-color: ${Colors.blueFeide};
       text-align: center;
       min-width: 16rem;
       width: 100%;
       font-weight: ${FontWeights.bold};

       &:hover{
        color: ${Colors.white};
        background-color: ${Colors.blueFeide};
      }

      &:active {
        color: ${Colors.white};
        background-color: ${Colors.blueFeide};
      }

       .MuiButton-startIcon{
         position: absolute;
         top: 50%;
         left: 1.6rem;
         transform: translateY(-50%);
       }
    `}

    // Pink Btn
    ${({ mode }) =>
      mode === 'pink' &&
      `
       color: ${Colors.white};
       background-color: ${Colors.pink};

       &:hover{
       background-color: ${Colors.darkPink};
      }
    `}

    // Primary dark btn
    ${({ mode }) =>
      mode === 'primaryDark' &&
      `
      color: ${Colors.black};
      background-color: ${Colors.primaryDark};
    `}
  }
  ${({ variant, color }) => {
    if (variant === 'outlined') {
      if (color === 'error') {
        return `color: ${Colors.white};`
      }
      return `color: ${Colors.dark};`
    }
    if (variant === 'text') {
      if (!color || color === 'inherit') return `color: ${Colors.dark};`

      if (color === 'error') return `color: ${Colors.danger};`

      return `color: ${Colors[color]};
      
      &:hover {
        color: ${Colors.white}
      }`
    }

    return ''
  }}
`
