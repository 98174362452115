import { Backdrop, CircularProgress } from '@mui/material'
import React from 'react'
import { BackdropPropTypes } from './Backdrop.types'

export const BackdropComponent: React.FC<BackdropPropTypes> = ({
  open,
  onClick,
  color = 'primary'
}) => {
  return (
    <Backdrop open={open} onClick={onClick}>
      <CircularProgress color={color} />
    </Backdrop>
  )
}
