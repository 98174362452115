import { api } from 'services/api/api'

const getLogout = async (token: string) =>
  api.get(`/fusionauth/logout`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

export default {
  get: getLogout
}
