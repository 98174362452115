import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useCookies } from 'react-cookie'
import { useMutation } from 'react-query'
import { TFuncKey } from 'react-i18next'

import { AuthRegisterPayloadTypes, UserServices } from 'services'
import { useAuthQuery, useSessionStorage } from 'hooks'
import { useLanguage } from 'context'

import { municipalities } from 'utils/getMunicipalities'

import {
  AuthContextTypes,
  AuthTypes,
  PlayingFromTypes
} from './Auth.context.types'

export const AuthContext = createContext<AuthContextTypes>(
  {} as AuthContextTypes
)

export const useAuth = () => useContext(AuthContext)

const getSearchParam = (key: string) => {
  return new URLSearchParams(window.location.search).get(key)
}

export const AuthContextWrapper: React.FC = ({ children }) => {
  const { register, login } = useAuthQuery()
  const { language } = useLanguage()

  const [cookies] = useCookies(['access_token'])

  const [error, setError] = useState<TFuncKey | string | null>(null)
  const [user, setUser] = useState<AuthTypes | null>(null)

  const playingFromStorage = useSessionStorage<PlayingFromTypes | null>(
    'playingFrom',
    null
  )

  const token = getSearchParam('token') || cookies.access_token

  const state = getSearchParam('state')

  const status = getSearchParam('status')

  const errorMessage = getSearchParam('message')

  const preventedRoutes = [
    'logout',
    'character',
    'pending_avatar',
    'redirect-to-game'
  ].some((path) => window.location.href.includes(path))

  const userInfoQuery = useMutation(
    ['/user/info', token],
    () => UserServices.UserInfo.get(token),
    {
      onSuccess: () => {
        if (!preventedRoutes && !error) {
          if (state && state !== sessionStorage.getItem('state')) {
            setError('general.status.error.sessionError')
            sessionStorage.clear()
            return
          }
          window.location.href = `${
            process.env.REACT_APP_GAME_BUILD_URL
          }?token=${token}&lang=${
            language.withoutAccent
          }&playingFrom=${playingFromStorage.getItem()}`
        }
      }
    }
  )

  const hasMunicipality = municipalities.some((municipality) =>
    window.location.pathname.includes(municipality)
  )

  const doRegister = async (payload: AuthRegisterPayloadTypes) => {
    const { profileId, token: resToken } = await register.mutateAsync({
      payload
    })
    if (profileId && resToken) return { profileId, token: resToken }
    return { profileId: undefined, token: undefined }
  }

  useEffect(() => {
    if (
      window.location.pathname !== '/' &&
      !playingFromStorage.getItem() &&
      !preventedRoutes &&
      !hasMunicipality
    ) {
      window.location.href = '/'
      return
    }
    userInfoQuery.mutate()
  }, [])

  useEffect(() => {
    if (errorMessage) {
      setError(errorMessage)
    }
  }, [status, errorMessage])

  const auth = useMemo(
    () => ({
      user,
      setUser,
      doAuth: login.mutate,
      doRegister,
      setPlayingFrom: playingFromStorage.setItem,
      playingFrom: playingFromStorage.getItem(),
      error,
      setError
    }),
    [setUser, login, doRegister, token, playingFromStorage, error, setError]
  )

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export * from './Auth.context.types'
