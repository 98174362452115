import React, { useContext } from 'react'
import { IconButton } from '@mui/material'
import { MusicNoteSharp, MusicOffSharp } from '@mui/icons-material'

import { LANGUAGES_DATA } from 'i18n'

import { AudioContext } from 'context'

import { LanguageSelectorComponent } from 'components'

import { DefaultHeaderStyles } from './Default.styles'

export const DefaultHeaderComponent: React.FC = () => {
  const { isPlayingMusic, stopMusic, playMusic } = useContext(AudioContext)

  return (
    <DefaultHeaderStyles>
      <LanguageSelectorComponent languages={LANGUAGES_DATA} />
      <IconButton
        color='primary'
        size='large'
        onClick={() => (isPlayingMusic ? stopMusic() : playMusic())}
        aria-label='play music'
      >
        {isPlayingMusic ? <MusicOffSharp /> : <MusicNoteSharp />}
      </IconButton>
    </DefaultHeaderStyles>
  )
}
