import { PopUpComponent } from 'components'
import { useParamsSelector } from 'hooks'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export type StatusTypes = 'pending_avatar' | 'login_failed'

export const ErrorPopupComponent = () => {
  const { t } = useTranslation()

  const [isOpen, setOpen] = useState(false)

  const params = useParamsSelector()

  const statusParam = params.get<StatusTypes>('status')
  const messageParam = params.get('message')

  useEffect(() => {
    if (statusParam === 'login_failed' && messageParam) setOpen(true)
    if (!statusParam || !messageParam) setOpen(false)
  }, [statusParam, messageParam])

  return (
    <PopUpComponent
      onClose={() => setOpen(false)}
      content={messageParam || undefined}
      variant='error'
      maxWidth='sm'
      title={t('general.status.error.somethingWentWrong')}
      open={isOpen}
    />
  )
}
