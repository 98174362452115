import React from 'react'

import {
  AuthContextWrapper,
  AudioContextWrapper,
  LanguageContextWrapper
} from '.'

export const GlobalContextWrapper: React.FC = ({ children }) => {
  return (
    <LanguageContextWrapper>
      <AudioContextWrapper>
        <AuthContextWrapper>{children}</AuthContextWrapper>
      </AudioContextWrapper>
    </LanguageContextWrapper>
  )
}
