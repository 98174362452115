import { AuthLocaleTypes } from '../locales.types'

const authLocaleEs: AuthLocaleTypes = {
  login: 'Iniciar sesión',
  logout: 'Cerrar sesión',
  signup: 'Registrarse',
  signout: 'Cerrar sesión',
  withFeide: 'Iniciar sesión con Feide',
  email: 'Correo electrónico',
  emailPlaceholder: 'ejemplo@email.com',
  password: 'Contraseña'
}

export default authLocaleEs
