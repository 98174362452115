import auth from './auth'
import general from './general'
import components from './components'
import pages from './pages'
import navigation from './navigation'

export default {
  auth,
  components,
  general,
  pages,
  navigation
}
