import { ComponentsLocaleTypes } from '../locales.types'

const componentsLocaleNb: ComponentsLocaleTypes = {
  character: {
    avatar: {
      comingSoon: 'Kommer snart'
    },
    input: {
      nameReady: 'Navn klart!'
    },
    layout: {
      header: {
        title: 'Velg din karakter',
        backbutton: 'Tilbake til forrige side',
        questionButton: 'Har du et spørsmål?'
      }
    }
  }
}

export default componentsLocaleNb
