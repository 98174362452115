import styled from 'styled-components'
import { Colors } from 'styles'
import { ButtonBase } from '@mui/material'

export const SpeakerButtonBaseStyles = styled(ButtonBase)`
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${Colors.secondary};
    min-height: 4.75rem;
    min-width: 4.75rem;
    border-radius: 0.5rem;
    border: 0.1rem solid transparent;
    transition: all ease-in-out 0.3s;

    :hover {
      border: 0.1rem solid ${Colors.primary};
      box-shadow: 0 0 2rem ${Colors.primary};
      svg {
        color: ${Colors.primary};
      }
    }

    svg {
      color: ${Colors.white};
      font-size: 3.1rem;
    }
  }
`
