import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { ParamKeyTypes, ParamTypes } from './useParamsSelector.types'

export const useParamsSelector = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const getQueryParam = <T extends string>(key: ParamKeyTypes) => {
    const param = searchParams.get(key) as T

    if (param) return param
    return null
  }

  const getAllParams = () => {
    const params: ParamTypes[] = []
    searchParams.forEach((value, key) =>
      params.push({ key: key as ParamKeyTypes, value })
    )
    return params
  }

  const removeAllParams = () => setSearchParams([])

  const addQueryParam = (queries: ParamTypes[]) => {
    setSearchParams(
      queries.reduce((acc: { [key: string]: string }, cur) => {
        acc[cur.key] = cur.value
        return acc
      }, {})
    )
  }

  const objParams = Object.assign(
    {},
    ...getAllParams().map(({ key, value }) => ({ [key]: value }))
  )

  const removeQueryParam = (key: ParamKeyTypes) => {
    setSearchParams(
      Object.assign(
        {},
        ...getAllParams().map(
          ({ key: cKey, value }) => cKey !== key && { [cKey]: value }
        )
      )
    )
  }

  return useMemo(
    () => ({
      getAll: getAllParams,
      deleteAll: removeAllParams,
      get: getQueryParam,
      add: addQueryParam,
      delete: removeQueryParam,
      objParams
    }),
    [
      searchParams,
      setSearchParams,

      getAllParams,
      removeAllParams,
      getQueryParam,
      addQueryParam,
      removeQueryParam,
      objParams
    ]
  )
}

export * from './useParamsSelector.types'
