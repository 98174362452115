import { Colors, FontSizes, FontWeights } from '..'

export const genericReset = (): string => `
  html, body {
    min-height: 100%;
    height: auto;
  }
  
  * {
    &,
    &:before,
    &:after {
      box-sizing: inherit;
      outline: none;
      font-family: 'Muli', sans-serif;
      text-transform: none;
      font-size:${FontSizes.normal};
    }
  }

  *:not(.MuiAccordion-root, .MuiPaper-elevation, [role="presentation"]):focus {
    outline: 0.2rem solid ${Colors.info} !important;
    outline-offset: -0.2rem;
    box-shadow: none;
  }

  body,
  h1, h2, h3, h4, h5, h6,
  blockquote, p, pre,
  dl, dd, ol, ul,
  figure,
  hr,
  fieldset, legend {
    margin: 0;
    padding: 0;
  }

  html, body, #root {
    font-family: 'Muli', sans-serif;
    font-size: 62.5%;
  }
  
  body {
    background-color:${Colors.black};
  }

  li > {
    ol,
    ul {
      margin-bottom: 0;
    }
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  fieldset {
    min-width: 0;
    border: 0;
  }

  button {
    cursor: pointer;
  }

  a, li {
    text-decoration: none;
  }

  a {
    color: inherit
  }

  img {
    width:100%;
    max-width:100%;
  }

  .success-text {
    color: ${Colors.success};
    font-weight: ${FontWeights.bold};
  }

  .MuiCalendarPicker-root {
    .MuiTypography-root {
      font-size: 1.4rem;
    }
  }

  button.MuiButtonBase-root.MuiPickersDay-root {
    font-size: 1.4rem;
  }
`
