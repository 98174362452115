import React from 'react'
import { Typography } from '@mui/material'
import { FontSizes } from 'styles'
import { ModalComponent, PopUpPropTypes } from 'components'
import {
  PopUpContainerStyles,
  PopUpHeaderStyles,
  PopUpContentStyles,
  PopUpFooterStyles
} from './PopUpModal.styles'
import { ResponseModalTemplate } from './templates'

import { ActionGroupComponent } from './components'

export const resolveTemplate = (data: PopUpPropTypes) => {
  if (!data.template)
    return <Typography fontSize={FontSizes.normal}>{data.content}</Typography>

  if (data.template === 'response') return <ResponseModalTemplate {...data} />
  return <div />
}

export const PopUpComponent: React.FC<PopUpPropTypes> = ({ ...props }) => {
  const {
    title,
    content,
    onClose,
    onConfirm,
    open,
    variant = 'primary',
    footer = {
      visible: true,
      align: 'flex-end'
    },
    disablePadding = false
  } = props

  return (
    <ModalComponent
      color={variant === 'error' ? 'error' : undefined}
      scroll={props.scroll}
      open={open}
      onClose={onClose}
    >
      <PopUpContainerStyles>
        {variant !== 'noHeader' && (
          <PopUpHeaderStyles variant={variant} fontSize={21} fontWeight='800'>
            <Typography fontWeight='bold' fontSize={FontSizes.h6}>
              {title}
            </Typography>
          </PopUpHeaderStyles>
        )}
        <PopUpContentStyles disablePadding={disablePadding}>
          {typeof content === 'string' ? resolveTemplate(props) : content}
        </PopUpContentStyles>
        {footer.visible && (
          <PopUpFooterStyles
            disablePadding={disablePadding}
            justifyContent={footer.align}
          >
            {footer.content ? (
              footer.content(
                <ActionGroupComponent
                  variant={variant}
                  onConfirm={onConfirm}
                  onClose={onClose}
                  footer={footer}
                />
              )
            ) : (
              <ActionGroupComponent
                variant={variant}
                onConfirm={onConfirm}
                onClose={onClose}
                footer={footer}
              />
            )}
          </PopUpFooterStyles>
        )}
      </PopUpContainerStyles>
    </ModalComponent>
  )
}

export * from './PopUpModal.types'
export * from './templates'
