/* eslint-disable no-shadow */
import veneraFont from 'assets/fonts/venera/Venera-900.otf'
import rajdhaniFont from 'assets/fonts/rajdhani/Rajdhani-Regular.ttf'
import muliFont from 'assets/fonts/muli/Muli-Regular.woff'
import muliBoldFont from 'assets/fonts/muli/Muli-Bold.woff'
import muliBlackFont from 'assets/fonts/muli/Muli-Black.woff'

import arboriaFont from 'assets/fonts/arboria/Arboria-Book.woff'
import arboriaBlackFont from 'assets/fonts/arboria/Arboria-Black.woff'

export enum FontSizes {
  smaller = '1.2rem',
  small = '1.4rem',
  normal = '1.6rem',
  normalPlus = '1.8rem',
  h6 = '2.1rem',
  h5 = '2.8rem',
  h4 = '3.7rem',
  h3 = '5.0rem',
  h2 = '6.7rem'
}

export enum FontWeights {
  normal = 400,
  bold = 600,
  extraBold = 800
}

export enum FontFamilies {
  muli = 'Muli',
  rajdhani = 'Rajdhani',
  venera = 'Venera',
  arboria = 'Arboria',
  arboriaBlack = 'Arboria Black'
}

export const genericTypography = (): string => `
  @font-face {
    font-family: 'Muli';
    font-display: swap;
    font-style: normal;
    font-weight: normal;
    src: local('Muli'), url(${muliFont}) format('woff');
  }

  @font-face {
    font-family: 'Muli';
    font-display: swap;
    font-style: normal;
    font-weight: 600;
    src: local('Muli'), url(${muliBoldFont}) format('woff');
  }

  @font-face {
    font-family: 'Muli';
    font-display: swap;
    font-style: normal;
    font-weight: 800;
    src: local('Muli'), url(${muliBlackFont}) format('woff');
  }
  
  @font-face {
    font-family: 'Rajdhani';
    font-display: swap;
    font-style: normal;
    font-weight: normal;
    src: local('Rajdhani'), url(${rajdhaniFont}) format('truetype');
  }

  @font-face {
    font-family: 'Venera';
    font-display: swap;
    font-style: normal;
    font-weight: normal;
    src: local('Venera'), url(${veneraFont}) format('opentype');
  }

  @font-face {
    font-family: 'Arboria';
    font-display: swap;
    font-style: normal;
    font-weight: normal;
    src: local('Arboria'), url(${arboriaFont}) format('woff');
  }

  @font-face {
    font-family: 'Arboria Black';
    font-display: swap;
    font-style: normal;
    font-weight: normal;
    src: local('Arboria Black'), url(${arboriaBlackFont}) format('woff');
  }
`
