import { ComponentsLocaleTypes } from '../locales.types'

const componentsLocalePt: ComponentsLocaleTypes = {
  character: {
    avatar: {
      comingSoon: 'Em Breve'
    },
    input: {
      nameReady: 'Nome'
    },
    layout: {
      header: {
        title: 'Escolha seu personagem',
        backbutton: 'Retornar para a página anterior',
        questionButton: 'Perguntar'
      }
    }
  }
}

export default componentsLocalePt
