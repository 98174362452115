import { AuthLocaleTypes } from '../locales.types'

const authLocalePt: AuthLocaleTypes = {
  login: 'Entrar',
  logout: 'Sair',
  signup: 'Cadastrar',
  signout: 'Sair',
  withFeide: 'Entrar usando Feide',
  email: 'E-mail',
  emailPlaceholder: 'exemplo@email.com',
  password: 'Senha'
}

export default authLocalePt
