import React, { ReactChild, ReactChildren } from 'react'
import { ThemeProvider } from 'styled-components'
import { breakpoints } from '../tools/breakpoints'
import { Colors } from '../tools/colors'
import { Theme } from './theme.types'

export const StyledTheme: Theme = {
  colors: Colors,
  state: {
    default: {
      primary: Colors.primary,
      secondary: Colors.secondary
    },
    success: {
      primary: Colors.success,
      secondary: Colors.successLight
    },
    info: {
      primary: Colors.info,
      secondary: Colors.infoLight
    },
    alert: {
      primary: Colors.warning,
      secondary: Colors.warningLight
    },
    danger: {
      primary: Colors.danger,
      secondary: Colors.dangerLight
    }
  },
  breakpoints
}

export const StyledThemeWrapper = ({
  children
}: {
  children: ReactChild | ReactChildren
}) => <ThemeProvider theme={StyledTheme}>{children}</ThemeProvider>
