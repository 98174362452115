import { Box } from '@mui/material'
import styled from 'styled-components'

export const StyledImage = styled.img`
  width: 16rem;
  height: 16rem;
  margin-bottom: 1.6rem;
  object-fit: cover;
`

export const ResponseModalStyles = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
