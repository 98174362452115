import styled from 'styled-components'
import { Colors, FontSizes } from 'styles'

export const LanguageSelectorItemStyles = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.2rem;
  background: ${Colors.black}15;
  cursor: pointer;

  p {
    color: ${Colors.white};
    font-size: ${FontSizes.small};
  }

  img {
    object-fit: cover;
    height: 2.4rem;
    width: 2.4rem;
    border-radius: 1.2rem;
  }
`
