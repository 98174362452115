import { useMutation } from 'react-query'

import {
  AuthRegisterPayloadTypes,
  AuthLoginPayloadTypes,
  AuthServices,
  AuthAccountPlayerEditPayloadTypes
} from 'services'
import { AuthValidateUsernameResponseTypes } from 'services/api/lib/auth/ValidateUsername.types'

export const useAuthQuery = () => {
  const getValidateUsername = useMutation(
    (username: string) =>
      AuthServices.ValidateUsername.get(
        username
      ) as Promise<AuthValidateUsernameResponseTypes>
  )
  const postAccountPlayer = useMutation(
    (data: { payload: AuthRegisterPayloadTypes; profileId: string }) =>
      AuthServices.AccountPlayer.post({
        avatarName: '',
        profileId: data.profileId,
        email: data.payload.AvatarName
      })
  )

  const putAccountPlayer = useMutation(
    (data: { payload: AuthAccountPlayerEditPayloadTypes; token: string }) =>
      AuthServices.AccountPlayer.put(data.payload, data.token)
  )

  const register = useMutation(
    (data: { payload: AuthRegisterPayloadTypes }) =>
      AuthServices.Register.post(data.payload),
    {
      retry: false
    }
  )

  const login = useMutation((payload: AuthLoginPayloadTypes) =>
    AuthServices.Login.post(payload)
  )

  return {
    getValidateUsername,
    postAccountPlayer,
    putAccountPlayer,
    login,
    register
  }
}
