/* eslint-disable no-shadow */
export enum Colors {
  primary = '#72FAFD',
  primaryLight = '#97FBFC',
  primaryDark = '#82D6D7',
  secondary = '#6300BE',
  secondaryLight = '#7700E6',
  secondaryDark = '#420080',
  light = '#818A91',
  dark = '#00034F',
  success = '#0FBE00',
  successLight = '#B2FFAC',
  successDark = '#0C8C00',
  danger = '#FF4D6F',
  dangerLight = '#FF8CA1',
  dangerDark = '#D9415D',
  info = '#007FFF',
  accentBlue = '#1D5BFF',
  infoLight = '#8CC6FF',
  infoDark = '#0060BF',
  warning = '#F7D424',
  warningLight = '#FFF1AC',
  warningDark = '#D9BA21',
  orange = '#FF7B48',
  orangeLight = '#FFD0A0',
  orangeDark = '#FF4800',
  blue = '#CCDAE8',
  darkBlue = '#040353',
  lightBlue = '#D9ECFF',
  lighterBlue = '#F4F9FF',
  white = '#FFFFFF',
  ghostWhite = '#F8F8FF',
  black = '#000000',
  blackAlt = '#1A1A1A',
  greyBg = '#F8F7F5',
  blueFeide = '#285CA9',
  lightGrey = '#DDDDDD',
  lighterGrey = '#E3E3E3',
  lightestGrey = '#F4F4F4',
  darkerGrey = '#868686',
  darkGrey = '#CCCCCC',
  cyan = '#04eaff',
  greenLight = '#00FF00',
  green = '#41d307',
  grey = '#ECF1F5',
  lightPink = '#EDD9FF',
  pink = '#B626AD',
  darkPink = '#740f6A'
}

export type ColorsTypes = typeof Colors
