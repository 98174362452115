import process from 'process'
import { api } from 'services/api/api'
import { AuthLoginPayloadTypes, AuthLoginResponseTypes } from './Login.types'

const Post = (
  payload: AuthLoginPayloadTypes
): Promise<AuthLoginResponseTypes> =>
  api
    .post<AuthLoginResponseTypes>('/fusionauth/login', {
      ...payload,
      applicationId: process.env.REACT_APP_ID_PROVIDER_ID
    })
    .then((data) => data.data)

export default {
  post: Post
}
