import axios from 'axios'
import process from 'process'
import { errorInterceptor } from './api.utils'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

api.interceptors.response.use(errorInterceptor)

const idProvider = axios.create({
  baseURL: process.env.REACT_APP_ID_PROVIDER_URL
})

idProvider.interceptors.response.use(errorInterceptor)

const teacherApi = axios.create({
  baseURL: process.env.REACT_APP_TEACHER_API
})

teacherApi.interceptors.response.use(errorInterceptor)

export { api, idProvider, teacherApi }
