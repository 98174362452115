import React from 'react'

import { ReactComponent as LeftImageCharacter } from 'assets/svg/layout/left-content.svg'
import { ReactComponent as RightImageCharacter } from 'assets/svg/layout/right-content.svg'
import { Box } from '@mui/material'
import { DefaultHeaderComponent } from './DefaultHeader'
import { DefaultFooterComponent } from './DefaultFooter'

import { DefaultContainerStyles, DefaultMainStyles } from './Default.styles'
import { DefaultLayoutPropTypes } from './Default.types'

export const DefaultLayoutComponent: React.FC<DefaultLayoutPropTypes> = ({
  children,
  footerStyle,
  hideFullScreenBtn,
  disableCharacters
}) => {
  return (
    <DefaultContainerStyles>
      <DefaultHeaderComponent />
      <DefaultMainStyles maxWidth='xl' component='main'>
        {!disableCharacters && <LeftImageCharacter />}
        <Box display='flex' flexDirection='column' alignItems='center' gap={2}>
          {children}
        </Box>
        {!disableCharacters && <RightImageCharacter />}
      </DefaultMainStyles>
      <DefaultFooterComponent
        hideFullScreenBtn={hideFullScreenBtn}
        footerStyle={footerStyle}
      />
    </DefaultContainerStyles>
  )
}
