import styled from 'styled-components'

export const TopHeaderWrapperStyles = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: -1rem;
  align-items: center;
  margin-bottom: 2rem;
  padding: 1.6rem;
`
