import React from 'react'
import { CheckCircle, CheckCircleOutlined } from '@mui/icons-material'

import { ButtonStyles } from './Button.styles'
import { ButtonPropTypes } from './Button.types'

export const ButtonComponent: React.FC<ButtonPropTypes> = ({
  value,
  fontSize = 'normal',
  mode,
  selected,
  variant,
  smaller,
  type = 'button',
  startIcon,
  endIcon = null,
  isHidden,
  children,
  ...rest
}) => {
  const renderEndIcon = () => {
    if (mode === 'selectBtn') {
      return selected ? <CheckCircle /> : <CheckCircleOutlined />
    }
    return null
  }

  return (
    <ButtonStyles
      endIcon={mode === 'selectBtn' ? renderEndIcon() : endIcon}
      startIcon={startIcon}
      mode={mode}
      selected={selected}
      disableRipple
      fontSize={fontSize}
      value={value}
      type={type}
      smaller={smaller}
      isHidden={isHidden}
      variant={mode === 'feideBtn' ? 'contained' : variant}
      {...rest}
    >
      {value || children}
    </ButtonStyles>
  )
}
