import React, { useMemo, useState } from 'react'
import { AnimateSharedLayout } from 'framer-motion'
import { StepperContext, StepContextProps } from 'context'
import { StepperParamsTypes, StepperPropTypes } from './Stepper.types'
import { AnimatedLayoutStyles } from './Stepper.styles'

export const StepperComponent = ({
  steps,
  header,
  footer
}: StepperPropTypes) => {
  const [actualStep, dispatchStep] = useState(0)
  const [params, setParams] = useState<StepperParamsTypes>({})
  const [stepHistory, setHistory] = useState<number[]>()
  const maxStep = steps.length

  const setActualStep = (index: number) => {
    setHistory((cur) => (cur ? [...cur, index] : [index]))
    dispatchStep(index)
  }

  const gotoStep = (to: string, parameters?: StepperParamsTypes) => {
    const toIndex = steps.findIndex((step) => step.name === to)
    if (!toIndex) return

    setActualStep(toIndex)
    if (parameters) setParams(parameters)
  }

  const goBack = () => {
    if (!stepHistory) return
    stepHistory?.pop()

    dispatchStep(stepHistory[stepHistory.length - 1] || 0)
  }

  const setStep = (desiredStep: number) => {
    if (desiredStep > steps.length || desiredStep < 0) return
    setActualStep(desiredStep)
  }

  const nextStep = (parameters?: StepperParamsTypes) => {
    if (actualStep + 1 === maxStep) return
    setActualStep(actualStep + 1)
    if (parameters) setParams(parameters)
  }

  const prevStep = (parameters?: StepperParamsTypes) => {
    if (actualStep - 1 < 0) return
    setActualStep(actualStep - 1)
    if (parameters) setParams(parameters)
  }

  const contextData: StepContextProps = useMemo(
    () => ({
      actualStep,
      maxSteps: maxStep - 1,
      setStep,
      nextStep,
      prevStep,
      gotoStep,
      params,
      goBack
    }),
    [actualStep, params]
  )

  const addornmentData = useMemo(
    () => ({
      navigator: {
        setStep,
        nextStep,
        prevStep,
        gotoStep,
        params,
        goBack
      },
      actualStep: {
        index: actualStep,
        name: steps[actualStep]?.name
      }
    }),
    [actualStep]
  )
  return (
    <StepperContext.Provider value={contextData}>
      <AnimateSharedLayout>
        <AnimatedLayoutStyles
          layout
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key={steps[actualStep]?.name}
        >
          {header && typeof header === 'function'
            ? header(addornmentData)
            : header}
          {steps[actualStep]?.component}
          {footer && typeof footer === 'function'
            ? footer(addornmentData)
            : footer}
        </AnimatedLayoutStyles>
      </AnimateSharedLayout>
    </StepperContext.Provider>
  )
}
