import React from 'react'
import {
  ButtonComponent,
  HeaderActionsComponent,
  LayoutComponent
} from 'components'
import { FormLayoutBackgroundStyles } from 'layout'
import { useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { Link, Typography } from '@mui/material'
import PcAndTabletSVG from 'assets/svg/pcAndTablet.svg'
import { HomeViewStyles, LogoHolderStyles } from '../home/Home.styles'

export const UnsuportedDevice = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <LayoutComponent pageTitle='Numetry'>
      <FormLayoutBackgroundStyles>
        <HeaderActionsComponent
          goBackAction={() => {
            navigate(-1)
          }}
        />
        <HomeViewStyles>
          <LogoHolderStyles>
            <img src={t('pages.home.logo')} alt='Numetry Logo' />
          </LogoHolderStyles>
          <Typography textAlign='center'>
            {t('pages.unsuportedDevice.title')}
          </Typography>
          <img src={PcAndTabletSVG} alt='Numetry running on macbook and ipad' />
          <ButtonComponent
            variant='contained'
            color='primary'
            href={process.env.REACT_APP_IOS_URL}
          >
            {t('pages.unsuportedDevice.button')}
          </ButtonComponent>
          <Typography textAlign='center'>
            <Trans
              i18nKey='pages.unsuportedDevice.footer'
              components={[
                <Link
                  display='block'
                  href={process.env.REACT_APP_APP_STORE_URL}
                  color='inherit'
                />
              ]}
            />
          </Typography>
        </HomeViewStyles>
      </FormLayoutBackgroundStyles>
    </LayoutComponent>
  )
}
