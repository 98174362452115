import React from 'react'
import { Typography } from '@mui/material'
import { Colors, FontSizes, FontWeights } from 'styles'
import { PropsResponseTemplate } from '../../PopUpModal.types'
import { StyledImage, ResponseModalStyles } from './ResponseModal.styles'

export const ResponseModalTemplate: React.FC<PropsResponseTemplate> = ({
  ...data
}) => {
  return (
    <ResponseModalStyles>
      <StyledImage alt={data.title} src={data.image} />
      <Typography
        fontSize={FontSizes.h6}
        fontWeight={FontWeights.bold}
        color={Colors.dark}
        mb={1}
      >
        {data.subtitle}
      </Typography>
      <Typography fontSize={FontSizes.normal}>{data.content}</Typography>
    </ResponseModalStyles>
  )
}
