import { api } from 'services/api/api'
import {
  AuthAccountPlayerEditPayloadTypes,
  AuthAccountPlayerPayloadTypes,
  AuthAccountTypes
} from './Account.Player.types'

const authorization = (token: string) => `Bearer ${token}`

const Get = async (accountProfileId: string, token: string) => {
  return api
    .get<AuthAccountTypes>(`/account/${accountProfileId}`, {
      headers: {
        Authorization: authorization(token)
      }
    })
    .then((response) => response.data)
}

const Post = async (payload: AuthAccountPlayerPayloadTypes) => {
  return api.post('/account/player', payload).then((data) => data.data)
}

const Put = async (
  payload: AuthAccountPlayerEditPayloadTypes,
  token: string
) => {
  return api.put<AuthAccountTypes>(`/games/profile`, payload, {
    headers: {
      Authorization: authorization(token)
    }
  })
}

export default {
  get: Get,
  post: Post,
  put: Put
}
