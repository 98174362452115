import { GeneralLocaleTypes } from '../locales.types'

const generalLocaleNb: GeneralLocaleTypes = {
  username: 'Brukernavn',
  imageAlt: {},
  status: {
    error: {
      somethingWentWrong: 'Hmm... Noe gikk galt!',
      sessionError: 'Kan ikke bekrefte økten, prøv igjen senere...'
    }
  },
  actions: {
    back: 'Tilbake',
    cancel: 'Avbryt',
    moveOn: 'Gå videre',
    remove: 'Fjern',
    next: 'Neste',
    save: 'Lagre {{ addOn }}',
    update: 'Oppdater {{ addOn }}',
    select: 'Velg',
    confirm: 'Bekreft',
    close: 'Lukk',
    revoke: 'Fjern',
    edit: 'Rediger',
    delete: 'Slett'
  },
  or: 'eller'
}

export default generalLocaleNb
