import enFlag from 'assets/images/flags/en.svg'
import noFlag from 'assets/images/flags/no.svg'
import brFlag from 'assets/images/flags/br.svg'
import esFlag from 'assets/images/flags/es.svg'

export type LanguageISO639Types = 'en' | 'nb' | 'nn' | 'pt' | 'es'
export type LanguageIdTypes = -1 | 0 | 1 | 2 | 3 | 4

export type LanguageTypes = {
  iso639: LanguageISO639Types
  id: LanguageIdTypes
  withAccent: string
  flag?: string
  disabled?: boolean
}
const isLanguageDisabled = (iso639: LanguageISO639Types) => {
  const envVar = `REACT_APP_DISABLE_${iso639.toUpperCase()}`
  return process.env[envVar] === String(true)
}

export const LANGUAGES_DATA: LanguageTypes[] = [
  {
    id: 0,
    iso639: 'en',
    withAccent: 'English',
    flag: enFlag,
    disabled: isLanguageDisabled('en')
  },
  {
    id: 1,
    iso639: 'nn',
    withAccent: 'Nynorsk',
    flag: noFlag,
    disabled: isLanguageDisabled('nn')
  },
  {
    id: 2,
    iso639: 'nb',
    withAccent: 'Bokmål',
    flag: noFlag,
    disabled: isLanguageDisabled('nb')
  },
  {
    id: 3,
    iso639: 'pt',
    withAccent: 'Portuguese',
    flag: brFlag,
    disabled: isLanguageDisabled('pt')
  },
  {
    id: 4,
    iso639: 'es',
    withAccent: 'Spanish',
    flag: esFlag,
    disabled: isLanguageDisabled('es')
  }
]

export const langTags = {
  nynorsk: 'nn',
  english: 'en',
  bokmal: 'nb',
  portuguese: 'pt-Br',
  spanish: 'es'
} as const
