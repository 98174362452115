export default {
  character: {
    avatar: {
      comingSoon: 'Coming soon'
    },
    input: {
      nameReady: 'Name ready'
    },
    layout: {
      header: {
        title: 'Choose your character',
        backbutton: 'Return to previous page',
        questionButton: 'Have a Question?'
      }
    }
  }
}
