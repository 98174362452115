import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { useAuth } from 'context'
import { TFuncKey, useTranslation } from 'react-i18next'
import { IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import { GlobalLayoutPropTypes, LocationLayoutTypes } from './Layout.types'
import { DefaultLayoutComponent } from './default/Default'
import { ErrorDisplayStyles } from './Layout.styles'

export const GlobalLayoutComponent: React.FC<GlobalLayoutPropTypes> = ({
  defaultLayout
}) => {
  const { t } = useTranslation()
  const { pathname } = useLocation() as unknown as LocationLayoutTypes
  const { error, setError } = useAuth()

  return (
    <>
      <ErrorDisplayStyles visible={error !== null}>
        {error && t(error as TFuncKey)}
        <IconButton onClick={() => setError(null)}>
          <Close />
        </IconButton>
      </ErrorDisplayStyles>
      <DefaultLayoutComponent
        hideFullScreenBtn={pathname === '/'}
        {...defaultLayout}
      >
        <Outlet />
      </DefaultLayoutComponent>
    </>
  )
}
