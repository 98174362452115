import { Menu } from '@mui/material'
import styled from 'styled-components'
import { Colors } from 'styles'

export const LanguageSelectorMenuStyles = styled(Menu)`
  .MuiPaper-root {
    background: transparent;
  }
`

export const LanguageSelectorMenuListStyles = styled.div`
  display: flex;
  flex-direction: column;
  border: 0.1rem solid ${Colors.primary};
  gap: 1rem;
  border-radius: 1rem;
  padding: 1.3rem 1rem;
  width: 14rem;

  background: ${Colors.black}90;
`
