import React from 'react'
import { Typography } from '@mui/material'

import { useLanguage } from 'context'

import { LanguageSelectorItemPropTypes } from './LanguageSelectorItem.types'
import { LanguageSelectorItemStyles } from './LanguageSelectorItem.styles'

export const LanguageSelectorItemComponent: React.FC<
  LanguageSelectorItemPropTypes
> = ({ flag, withAccent, iso639, disabled }) => {
  const { changeLanguage } = useLanguage()

  if (disabled) return null

  return (
    <LanguageSelectorItemStyles onClick={() => changeLanguage(iso639)}>
      <img src={flag} alt={iso639} />
      <Typography>{withAccent}</Typography>
    </LanguageSelectorItemStyles>
  )
}

export * from './LanguageSelectorItem.types'
