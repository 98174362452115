import React, { createContext, useMemo, useState } from 'react'

import backgroundMusic from 'assets/audios/background.mp3'

import { AudioContextTypes } from './Audio.context.types'

export const AudioContext = createContext<AudioContextTypes>({
  isPlayingMusic: false,
  playMusic: () => {},
  stopMusic: () => {}
})

const music = new Audio(backgroundMusic)

export const AudioContextWrapper: React.FC = ({ children }) => {
  const [isPlayingMusic, setPlayMusic] = useState(false)

  const playMusic = () => {
    if (!isPlayingMusic) {
      music.play()
      setPlayMusic(true)
    }
  }

  const stopMusic = () => {
    if (isPlayingMusic) {
      music.pause()
      setPlayMusic(false)
    }
  }

  const audio = useMemo(
    () => ({
      isPlayingMusic,
      playMusic,
      stopMusic
    }),
    [isPlayingMusic]
  )

  return <AudioContext.Provider value={audio}>{children}</AudioContext.Provider>
}
