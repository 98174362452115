import React, { useCallback } from 'react'
import { ChevronLeftSharp } from '@mui/icons-material'
import { ButtonBase, Typography } from '@mui/material'
import { FontSizes, FontWeights } from 'styles'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { TopHeaderWrapperStyles } from './HeaderActions.styles'
import { HeaderActionsTypes } from './HeaderActions.types'

export const HeaderActionsComponent: React.FC<HeaderActionsTypes> = ({
  goBackAction
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const defaultAction = useCallback(() => {
    navigate(-1)
  }, [])

  return (
    <TopHeaderWrapperStyles>
      <ButtonBase onClick={goBackAction || defaultAction}>
        <ChevronLeftSharp fontSize='medium' />
        <Typography fontWeight={FontWeights.bold} fontSize={FontSizes.small}>
          {t('general.actions.back')}
        </Typography>
      </ButtonBase>
    </TopHeaderWrapperStyles>
  )
}
