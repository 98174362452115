export default {
  home: {
    title: 'Inicio'
  },
  signUp: {
    title: 'Registrarse',
    username: {
      title: 'Nombre de usuario'
    },
    password: {
      title: 'Contraseña'
    },
    review: {
      title: 'Revisar'
    }
  },
  signIn: {
    title: 'Iniciar sesión'
  },
  character: {
    title: 'Personaje'
  },
  logout: {
    title: 'Cerrar sesión'
  },
  municipality: {
    title: 'Municipio'
  }
}
