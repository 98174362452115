import { Dialog, IconButton } from '@mui/material'
import styled from 'styled-components'
import { Colors } from 'styles'
import { from } from 'styles/tools/breakpoints'

export const ModalStyles = styled(Dialog)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5;

  .MuiDialog-container,
  .MuiDialog-paper {
    width: 100%;
  }
  .MuiDialog-paper {
    overflow: visible;
  }
`

export const CloseButtonStyles = styled(IconButton)`
  display: none;
  position: absolute;
  right: 0;
  background-color: ${({ color }) =>
    color === 'error' ? Colors.danger : Colors.info};
  padding: 1rem;
  margin-right: -2rem;
  margin-top: -2rem;
  box-shadow: 0 0 0.2rem ${Colors.infoDark}29;
  max-height: 4.4rem;
  transition: all 0.25s ease;
  z-index: 10;

  svg {
    color: ${Colors.white};
  }

  ${from.md} {
    display: initial;
  }

  :hover {
    background-color: ${({ color }) =>
      color === 'error' ? Colors.dangerDark : Colors.infoDark};
    transition: all 0.25s ease;
  }
`
