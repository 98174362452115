import React from 'react'
import { CircularProgress, Icon } from '@mui/material'
import { motion, AnimateSharedLayout } from 'framer-motion'
import WarningIcon from 'assets/svg/icons/warning.svg'
import SuccessIcon from 'assets/svg/icons/ok_check.svg'
import { StatusAddonStyles } from '../Input.styles'
import { InputStatusAddonPropTypes } from './InputStatusAddon.types'

const animatePreset = {
  initial: { opacity: 0, rotate: 0 },
  exit: { opacity: 0, rotate: 0 },
  animate: { opacity: 1, rotate: 360 }
}

export const InputStatusAddon: React.FC<InputStatusAddonPropTypes> = ({
  status,
  name,
  hideError,
  hideSuccess,
  hideLoading,
  disableExternalStatus
}) => {
  return (
    <AnimateSharedLayout>
      <StatusAddonStyles
        layout
        key={name}
        disableExternalStatus={disableExternalStatus}
      >
        <Icon>
          {!hideError && status === 'error' && (
            <motion.img
              key={`${name}_errorIcon`}
              {...animatePreset}
              alt='warningIcon'
              src={WarningIcon}
            />
          )}
          {!hideSuccess && status === 'success' && (
            <motion.img
              key={`${name}_successIcon`}
              {...animatePreset}
              alt='successIcon'
              src={SuccessIcon}
            />
          )}
          {!hideLoading && status === 'loading' && (
            <CircularProgress size='2rem' color='secondary' />
          )}
        </Icon>
      </StatusAddonStyles>
    </AnimateSharedLayout>
  )
}
