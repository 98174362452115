import { api } from 'services/api/api'
import {
  AuthRegisterPayloadTypes,
  AuthRegisterResponseTypes
} from './Register.types'

const Post = async (payload: AuthRegisterPayloadTypes) => {
  return api
    .post<AuthRegisterResponseTypes>('/games/account/fusionauth', payload)
    .then(({ data: res }) => res.data)
}

export default {
  post: Post
}
