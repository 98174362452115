import React from 'react'
import { Box, Typography, SvgIcon, IconButton } from '@mui/material'
import { Fullscreen, FullscreenExit } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import { ReactComponent as EduplaytionLogo } from 'assets/svg/company/Eduplaytion_Logo.svg'

import { DefaultFooterStyles } from './Default.styles'
import { DefaultFooterPropTypes } from './Default.types'

export const DefaultFooterComponent: React.FC<DefaultFooterPropTypes> = ({
  footerStyle,
  hideFullScreenBtn
}) => {
  const { t } = useTranslation()

  return (
    <DefaultFooterStyles>
      <Box
        sx={{
          width: 'max-content',
          zIndex: 5,
          textAlign: 'center',
          ...footerStyle
        }}
      >
        <Typography>{t('pages.home.footer.aGameBy')}</Typography>
        <EduplaytionLogo width='15rem' />
      </Box>
      {!hideFullScreenBtn && (
        <IconButton
          color='primary'
          onClick={() =>
            document.fullscreenElement
              ? document.exitFullscreen()
              : document.documentElement.requestFullscreen()
          }
        >
          <SvgIcon color='primary' fontSize='large'>
            {document.fullscreenElement ? <FullscreenExit /> : <Fullscreen />}
          </SvgIcon>
        </IconButton>
      )}
    </DefaultFooterStyles>
  )
}
