import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  LanguageSelectorItemComponent,
  LanguageSelectorMenuComponent,
  LanguageSelectorViewerComponent
} from './components'
import { LanguageSelectorPropTypes } from './LanguageSelector.types'

export const LanguageSelectorComponent: React.FC<LanguageSelectorPropTypes> = ({
  languages
}) => {
  const { i18n } = useTranslation()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const handleClose = () => setAnchorEl(null)

  return (
    <div>
      <LanguageSelectorViewerComponent
        aria-haspopup='true'
        open={open}
        onClick={(e) =>
          anchorEl ? handleClose() : setAnchorEl(e.currentTarget)
        }
        lang={
          languages.find((lang) => lang.iso639 === i18n.language) ||
          languages[languages.findIndex((lang) => lang.iso639 === 'nb')]
        }
      />
      <LanguageSelectorMenuComponent
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {languages.map((lang) => (
          <LanguageSelectorItemComponent
            key={`${lang.iso639}-selector`}
            {...lang}
          />
        ))}
      </LanguageSelectorMenuComponent>
    </div>
  )
}
