import styled from 'styled-components'
import { Colors, FontFamilies, FontSizes } from 'styles'
import { HeaderTitleWrapperTypes } from './HeaderTitle.types'

export const HeaderTitleWrapperStyles = styled.div<HeaderTitleWrapperTypes>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.4rem;
  margin-bottom: ${({ margin }) => {
    if (margin === 'normal') return '1rem'
    if (margin === 'big') return '2rem'
    return '0'
  }};
  p {
    color: ${Colors.white};
    font-size: ${FontSizes.h6};
    font-family: ${FontFamilies.arboriaBlack};
    margin-right: 0.5rem;
  }

  ${({ fontSize }) =>
    fontSize === 'lg' &&
    `
    p {
      font-size: ${FontSizes.h4};
    }
  `}
`
