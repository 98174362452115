import { ComponentsLocaleTypes } from '../locales.types'

const componentsLocaleEs: ComponentsLocaleTypes = {
  character: {
    avatar: {
      comingSoon: 'Próximamente '
    },
    input: {
      nameReady: 'Nombre listo!'
    },
    layout: {
      header: {
        title: 'Selecciona tu personaje',
        backbutton: 'Atrás',
        questionButton: '¿Tienes una pregunta?'
      }
    }
  }
}
export default componentsLocaleEs
