import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { ErrorPopupComponent } from './components'

import { LayoutPropTypes } from './Layout.types'

export const LayoutComponent: React.FC<LayoutPropTypes> = ({
  pageTitle,
  children
}) => {
  const { i18n } = useTranslation()
  const location = useLocation()

  const updatePageTitle = (newTitle: string) => {
    document.title = newTitle
  }

  useEffect(() => {
    const concatenatedTitle = pageTitle ? `- ${pageTitle}` : ''
    updatePageTitle(`Numetry ${concatenatedTitle}`)
  }, [location, i18n.language])

  return (
    <>
      <ErrorPopupComponent />
      {children}
    </>
  )
}

export * from './Layout.types'
