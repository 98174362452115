import { CssBaseline } from '@mui/material'
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles'
import React, { ReactElement } from 'react'
import { Colors } from '../tools/colors'
import { MuiThemeTypes } from './theme.types'

export const MuiTheme: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: Colors.primary,
      light: Colors.primaryLight,
      dark: Colors.primaryDark
    },
    secondary: {
      main: Colors.secondary,
      light: Colors.secondaryLight,
      dark: Colors.secondaryDark
    },
    info: {
      main: Colors.info,
      light: Colors.infoLight,
      dark: Colors.infoDark
    },
    success: {
      main: Colors.success,
      light: Colors.successLight,
      dark: Colors.successDark
    },
    warning: {
      main: Colors.warning,
      light: Colors.warningLight,
      dark: Colors.warningDark
    },
    error: {
      main: Colors.danger,
      light: Colors.dangerLight,
      dark: Colors.dangerDark
    }
  },
  typography: {
    fontFamily: ['Muli', 'Rajdhani', 'Venera', 'Arboria', 'sans-serif'].join(
      ','
    ),
    htmlFontSize: 10
  },
  components: {
    MuiDivider: {
      defaultProps: {
        sx: {
          ':after': {
            borderTop: '0.1rem solid rgba(255, 255, 255, 0.22)',
            top: 0
          },
          ':before': {
            borderTop: '0.1rem solid rgba(255, 255, 255, 0.22)',
            top: 0
          }
        }
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1200,
      xl: 1728
    }
  }
}

export const MuiThemeWrapper = ({ children }: MuiThemeTypes): ReactElement => {
  const theme = createTheme(MuiTheme)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export * from './theme.types'
