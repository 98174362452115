import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { withProfiler } from '@sentry/react'
import { CookiesProvider } from 'react-cookie'
import { MotionConfig } from 'framer-motion'

import { Router } from 'router/Router'

import { GlobalContextWrapper } from 'context'
import { GlobalStyles, MuiThemeWrapper } from 'styles'

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } }
})

const App: React.FC = () => {
  return (
    <React.StrictMode>
      <CookiesProvider>
        <QueryClientProvider client={queryClient}>
          <MotionConfig
            transition={{
              type: 'spring',
              damping: 10,
              mass: 0.75,
              stiffness: 100
            }}
          >
            <GlobalContextWrapper>
              <MuiThemeWrapper>
                <>
                  <GlobalStyles />
                  <Router />
                </>
              </MuiThemeWrapper>
            </GlobalContextWrapper>
          </MotionConfig>
        </QueryClientProvider>
      </CookiesProvider>
    </React.StrictMode>
  )
}

export default withProfiler(App)
