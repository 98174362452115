import styled from 'styled-components'
import { Colors, FontSizes, from } from 'styles'
import { LanguageSelectorViewerTypes } from './LanguageSelectorViewer.types'

export const LanguageSelectorViewerStyles = styled.button<LanguageSelectorViewerTypes>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 0.1rem solid ${Colors.primary};

  width: max-content;

  ${from.md} {
    min-width: 14rem;
  }

  gap: 1rem;
  background: ${Colors.black}90;
  border-radius: 7rem;
  justify-content: space-between;
  padding: 0.8rem 1rem;

  p {
    color: ${Colors.white};
    font-size: ${FontSizes.small};
  }

  img {
    object-fit: cover;
    height: 2.4rem;
    width: 2.4rem;
    border-radius: 1.2rem;
  }
`
