import React, { forwardRef } from 'react'
import { TransitionProps } from '@mui/material/transitions'
import { Slide } from '@mui/material'

import { ModalPropTypes } from './Modal.types'
import { ModalStyles } from './Modal.styles'

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

export const ModalComponent: React.FC<ModalPropTypes> = ({
  children,
  color,
  ...modalProps
}) => {
  return (
    <ModalStyles
      {...modalProps}
      open={modalProps.open}
      TransitionComponent={Transition}
      closeAfterTransition
      keepMounted
      color={color}
    >
      {children}
    </ModalStyles>
  )
}

export * from './Modal.types'
export * from './components'
