import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { SvgIcon, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useLanguage } from 'context/language/Language.context'
import React from 'react'
import { LanguageSelectorViewerStyles } from './LanguageSelectorViewer.styles'
import { LanguageSelectorViewerPropTypes } from './LanguageSelectorViewer.types'

export const LanguageSelectorViewerComponent: React.FC<
  LanguageSelectorViewerPropTypes
> = ({ lang, open, onClick }) => {
  const { changeLanguage } = useLanguage()
  const isMobileScreen = useMediaQuery(useTheme().breakpoints.down('md'))

  return (
    <LanguageSelectorViewerStyles
      open={open}
      onClick={(e) => {
        if (!onClick) changeLanguage(lang.iso639)
        else onClick(e)
      }}
    >
      <img src={lang.flag} alt={`${lang.iso639}-flag`} />
      {!isMobileScreen && <Typography>{lang.withAccent}</Typography>}
      <SvgIcon
        component={!open ? KeyboardArrowDown : KeyboardArrowUp}
        inheritViewBox
        color='primary'
      />
    </LanguageSelectorViewerStyles>
  )
}
