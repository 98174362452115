import styled from 'styled-components'
import { Colors, FontWeights } from 'styles'

export const ErrorDisplayStyles = styled.div<{ visible?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  width: 100%;
  transition: all 0.25s ease;
  background: ${Colors.danger};
  color: ${Colors.white};
  font-weight: ${FontWeights.bold};

  ${({ visible }) => {
    if (visible) {
      return `
      content-visibility: visible;
      `
    }
    return `
    display: none;
    content-visibility: hidden;
    `
  }}
`
