import styled from 'styled-components'
import { Box } from '@mui/material'
import { Colors } from 'styles'

import { HeaderProps } from './PopUpModal.types'

export const PopUpContainerStyles = styled(Box)`
  display: block;
  box-shadow: 0 -0.4rem 2rem ${Colors.black}29;
`
export const PopUpHeaderStyles = styled(Box)<HeaderProps>`
  padding: 1.8rem;

  ${({ variant }) => {
    if (variant === 'primary') {
      return `
      background: ${Colors.darkBlue};
      color: ${Colors.white};
      `
    }
    if (variant === 'secondary') {
      return `
      background: ${Colors.secondary};
      color: ${Colors.white};
      `
    }
    if (variant === 'light') {
      return `
      background: ${Colors.lighterBlue};
      color: ${Colors.dark};
      border-bottom: 0.2rem solid ${Colors.lightBlue};
      `
    }
    if (variant === 'error') {
      return `
      background: ${Colors.dangerDark};
      color: ${Colors.white};
      border-bottom: 0.2rem solid ${Colors.dangerLight};
      `
    }
    return ``
  }}
`
export const PopUpContentStyles = styled(Box)<{ disablePadding?: boolean }>`
  padding: ${({ disablePadding }) => (disablePadding ? 0 : 2.18)}rem;
  background: ${Colors.white};
  color: ${Colors.dark};
`
export const PopUpFooterStyles = styled(Box)<{ disablePadding?: boolean }>`
  background: ${Colors.white};
  display: flex;
  min-width: 100%;
  padding: ${({ disablePadding }) => (disablePadding ? 0 : 2.18)}rem;
  padding-top: 0;
`
