export default {
  home: {
    title: 'Início'
  },
  signUp: {
    title: 'Cadastrar',
    username: {
      title: 'Nome de Usuário'
    },
    password: {
      title: 'Senha'
    },
    review: {
      title: 'Revisão'
    }
  },
  signIn: {
    title: 'Entrar'
  },
  character: {
    title: 'Personagem'
  },
  logout: {
    title: 'Sair'
  },
  municipality: {
    title: 'Município'
  }
}
