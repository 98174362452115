import { FormHelperText, InputBase, FormLabel, Stack } from '@mui/material'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { Colors, FontSizes } from 'styles'
import { InputHelperTextTypes, InputPropTypes } from './Input.types'

export const InputLabelStyles = styled(FormLabel)`
  font-weight: normal;
  font-size: ${FontSizes.normal};
  margin-bottom: 1.25rem;
`

export const InputStyles = styled(Stack)`
  position: relative;
  align-content: center;
  align-items: center;
  height: 100%;
`

export const InputComponentStyles = styled(InputBase)<InputPropTypes>`
  width: 100%;
  min-height: 3.2rem;
  height: 100%;
  color: ${Colors.black};
  font-size: ${FontSizes.normal};
  border-width: 0.2rem;
  border-style: solid;
  border-radius: 0.5rem;
  background: ${Colors.white};

  .MuiInputBase-input {
    padding: 1rem;

    &::placeholder {
      color: ${Colors.blackAlt};
      font-size: ${FontSizes.normal};
    }

    &:disabled,
    .Mui-disabled {
      -webkit-text-fill-color: ${Colors.dark};
      opacity: 0.5;
    }
  }

  .MuiIconButton-root {
    color: ${Colors.darkGrey};
  }
`

export const HelperTextStyles = styled(FormHelperText)<InputHelperTextTypes>`
  position: relative;
  text-align: left;
  margin-top: 0.5rem;
  font-size: ${FontSizes.small};
  color: ${Colors.danger}!important;
  visibility: ${({ visibility }) => visibility};
  ${({ visibility }) => visibility !== 'hidden' && `padding-bottom: 1.6rem;`};
`

export const StatusAddonStyles = styled(motion.div)<{
  disableExternalStatus?: boolean
}>`
  height: 100%;
  margin-left: 0.5rem;
  margin-right: -2.6rem;

  .MuiIcon-root {
    width: 3rem;
    height: 100%;

    position: absolute;
    top: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    ${({ disableExternalStatus }) =>
      disableExternalStatus &&
      `
      right: 1rem;
  `};
  }

  img {
    display: flex;
    height: inherit;
    width: inherit;
  }
`
