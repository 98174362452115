/* eslint-disable no-unneeded-ternary */
import { FormControl } from '@mui/material'
import { AnimatePresence } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { Colors } from 'styles'
import {
  HelperTextStyles,
  InputComponentStyles,
  InputLabelStyles,
  InputStyles
} from './Input.styles'
import { InputPropTypes } from './Input.types'
import { InputStatusAddon } from './InputStatusAddon/InputStatusAddon'

export const InputComponent: React.FC<InputPropTypes> = ({
  state = 'default',
  name,
  label,
  errorMessage,
  disabled,
  disableErrorMessage,
  statusAddon,
  type,
  disableExternalStatus,
  ...rest
}) => {
  const [inputBorderColor, setInputBorderColor] = useState('')

  useEffect(() => {
    if (state === 'error') {
      setInputBorderColor(Colors.danger)
      return
    }

    if (state === 'success') {
      if (disableExternalStatus) setInputBorderColor(Colors.primary)
      else setInputBorderColor(Colors.success)
      return
    }

    setInputBorderColor('#DDDDDD')
  }, [state])

  return (
    <FormControl margin='none' fullWidth={rest.fullWidth} variant='standard'>
      {label && (
        <InputLabelStyles
          sx={{ color: 'white' }}
          htmlFor={name}
          focused={false}
        >
          {label}
        </InputLabelStyles>
      )}

      <InputStyles flexDirection='row'>
        <InputComponentStyles
          {...rest}
          id={name}
          name={name}
          type={type}
          disabled={disabled}
          sx={{
            borderColor: inputBorderColor,
            input: {
              color:
                state === 'error' && type !== 'password'
                  ? inputBorderColor
                  : Colors.black
            }
          }}
        />
        {statusAddon && state !== 'default' && (
          <AnimatePresence>
            <InputStatusAddon
              disableExternalStatus={disableExternalStatus}
              hideError={
                typeof statusAddon === 'object' ? statusAddon?.hideError : false
              }
              hideSuccess={
                typeof statusAddon === 'object'
                  ? statusAddon?.hideSuccess
                  : false
              }
              hideLoading={
                typeof statusAddon === 'object'
                  ? statusAddon?.hideLoading
                  : false
              }
              name={`${name}_statusAddon`}
              status={state}
            />
          </AnimatePresence>
        )}
      </InputStyles>

      {!disableErrorMessage && (
        <HelperTextStyles
          id={name}
          visibility={errorMessage && state === 'error' ? 'initial' : 'hidden'}
        >
          {errorMessage || '.'}
        </HelperTextStyles>
      )}
    </FormControl>
  )
}
