export default {
  home: {
    title: 'Home'
  },
  signUp: {
    title: 'Sign Up',
    username: {
      title: 'Username'
    },
    password: {
      title: 'Password'
    },
    review: {
      title: 'Review'
    }
  },
  signIn: {
    title: 'Sign In'
  },
  character: {
    title: 'Character'
  },
  logout: {
    title: 'Logout'
  },
  municipality: {
    title: 'Municipality'
  }
}
