/* eslint-disable no-console */
import { useState } from 'react'
import { isObject } from 'utils/isObject'

export const useSessionStorage = <T = unknown>(
  key: string,
  initialValue: T
) => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.sessionStorage.getItem(key)
      return item ? (JSON.parse(item) as T) : initialValue
    } catch (error) {
      return initialValue
    }
  })

  const setItem = (newValue: T) => {
    try {
      setStoredValue(newValue)

      window.sessionStorage.setItem(
        key,
        isObject(newValue) ? JSON.stringify(newValue) : String(newValue)
      )
    } catch (error) {
      console.error(error)
    }
  }

  const removeItem = () => {
    try {
      window.sessionStorage.removeItem(key)
    } catch (error) {
      console.error(error)
    }
  }

  return {
    getItem: () => storedValue,
    setItem,
    removeItem
  }
}
